<template>
  <div
    class="v-application v-application--is-ltr theme--light menuable__content__active"
  >
    <!--begin::Attendance-->
    <div class="row">
      <div
        class="col-xxl-2 col-12 col-md-2 pt-0 pb-0"
        v-if="
          permission.some((item) => item.name === 'can_view_presence_report')
        "
      >
        <div class="card gutter-b">
          <b-form-select
            v-model="selectCustomer"
            :options="filterCustomer"
          ></b-form-select>
        </div>
      </div>
      <div
        class="col-xxl-2 col-12 col-md-2 pt-0 pb-0"
        v-if="
          permission.some((item) => item.name === 'can_view_presence_report')
        "
      >
        <div class="card gutter-b">
          <b-form-select
            v-model="selected.customerBranch"
            :options="filterCustomerBranch"
          ></b-form-select>
        </div>
      </div>
      <div class="col-xxl-2 col-12 col-md-2 pt-0 pb-0">
        <div class="card gutter-b">
          <b-form-datepicker
            v-model="params.start_date"
            placeholder="Start date"
            today-button
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
          ></b-form-datepicker>
        </div>
      </div>
      <div class="col-xxl-2 col-12 col-md-2 pt-0 pb-0">
        <div class="card gutter-b">
          <b-form-datepicker
            v-model="params.end_date"
            placeholder="End date"
            today-button
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
          ></b-form-datepicker>
        </div>
      </div>
      <div class="col-xxl-2 col-12 col-md-2 pt-0 pb-0" v-show="false">
        <b-form-group class="mb-0" v-slot="{ ariaDescribedby }">
          <b-input-group>
            <b-form-select
              id="sort-by-select"
              v-model="refSortBy"
              :options="sortOptions"
              :aria-describedby="ariaDescribedby"
              class="w-75"
            >
              <template #first>
                <option value="">-- Sort by --</option>
              </template>
            </b-form-select>

            <b-form-select
              v-model="refSortDirectionChange"
              :disabled="!sortBy"
              :aria-describedby="ariaDescribedby"
              class="w-25"
            >
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="col-xxl-2 col-12 col-md-2 pt-0 pb-0">
        <div class="card gutter-b">
          <b-button variant="primary" class="w-100" @click="exportAttendance"
            >Export Excel</b-button
          >
        </div>
      </div>
      <div class="col-xxl-2 col-12 col-md-2 pt-0 pb-0">
        <div class="card gutter-b">
          <b-button
            variant="primary"
            class="w-100"
            @click="exportAttendanceDetail"
            >Export Detail to Excel</b-button
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xxl-12 col-12 col-md-12">
        <b-card>
          <div class="row justify-content-between">
            <div class="col-md-1">
              <b-form-group class="mb-0">
                <b-form-select
                  id="per-page-select"
                  v-model="params.limit"
                  :options="pageOptions"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-group class="mb-0">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text>
                      <i class="menu-icon flaticon-search"></i>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    v-model="params.query"
                    placeholder="Search"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
          <div class="table-responsive">
            <b-table
              hover
              :items="listTable"
              :fields="columns"
              :per-page="params.limit"
              :current-page="refCurrentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="isLoading"
              show-empty
            >
              <!-- <template #cell(id)="row">
              <span class="mr-2">
                <span
                  :title="row.item.id"
                  class="cursor-pointer"
                  v-clipboard:copy="row.item.id"
                  v-clipboard:success="onCopy"
                  >{{ sortChar(row.item.id) }}</span
                >
                <span> </span>
              </span>
            </template> -->
              <template #cell(action)="row">
                <span class="mr-2" @click="handleView(row.item)">
                  <b-button
                    variant="primary"
                    class="py-1 px-2"
                    id="detail"
                    title="detail"
                  >
                    <i class="menu-icon flaticon-eye pr-0"></i>
                  </b-button>
                </span>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table>
          </div>
          <div class="row justify-content-end">
            <div class="col-md-3">
              <b-pagination
                v-model="refCurrentPage"
                :total-rows="totalData"
                :per-page="params.limit"
                size="md"
                align="fill"
                class="ml-auto"
                style="padding: 0"
              ></b-pagination>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <!--end::Attendance-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { sortChar } from "@/utils/helper";
import { debounce } from "debounce";
import { mapGetters } from "vuex";
import { dateToISOString } from "@/utils/helper";
import mixin from "@/mixins/index";

export default {
  name: "ListAttendance",
  mixins: [mixin],
  data() {
    let dateNow = dateToISOString(
      new Date(new Date()).setDate(new Date().getDate() + 1)
    );
    return {
      filterCustomer: [{ value: null, text: "Filter Site Group" }],
      filterCustomerBranch: [{ value: null, text: "Filter Site" }],
      selected: {
        customer: null,
        customerBranch: null,
      },
      isLoading: false,
      rowStyleOption: {
        stripe: true,
      },
      search: "",
      pageIndex: 1,
      totalRows: 10,
      currentPage: 1,
      pageSize: 10,
      perPage: 10,
      pageOptions: [10, 15, { value: 100, text: "Show a lot" }],
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
      columns: [
        {
          key: "name",
          label: "Name",
          align: "left",
          sortBy: "name",
          width: "",
          sortable: true,
        },
        {
          key: "date",
          label: "Date",
          align: "left",
          sortBy: "date",
          width: "30%",
          sortable: true,
        },
        {
          key: "must_present",
          label: "Total Attendance",
          align: "left",
          width: "",
          sortBy: "must_present",
          sortable: true,
        },
        {
          key: "present",
          label: "Present",
          align: "left",
          width: "",
          sortBy: "present",
          sortable: true,
        },
        {
          key: "absent",
          label: "Absent",
          align: "left",
          width: "",
          sortBy: "absent",
          sortable: true,
        },
        {
          key: "sick",
          label: "Sick",
          align: "left",
          width: "",
          sortBy: "sick",
          sortable: true,
        },
        {
          key: "leave",
          label: "Leave",
          align: "left",
          width: "",
          sortBy: "leave",
          sortable: true,
        },
        {
          key: "alpha",
          label: "Alpha",
          align: "left",
          width: "",
          sortBy: "alpha",
          sortable: true,
        },
        {
          key: "other_leave",
          label: "Other Leave",
          align: "left",
          width: "",
          sortBy: "other_leave",
          sortable: true,
        },
        {
          key: "action",
          label: "Action",
          align: "center",
          width: "10%",
        },
      ],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      listTable: [],
      totalData: 10,
      filter: null,
      filterOn: [],
      params: {
        query: "",
        sort: "created_at",
        order: "desc",
        limit: 10,
        offset: 0,
        start_date: "2022-01-01",
        end_date: dateNow,
      },
      timer: 0,
    };
  },
  components: {},
  computed: {
    ...mapGetters(["permission"]),
    refCurrentPage: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.params.offset = (value - 1) * this.params.limit;
      },
    },
    refSortBy: {
      get() {
        return this.sortBy;
      },
      set(value) {
        this.sortBy = value;
        this.params.sort = value;
      },
    },
    refSortDirectionChange: {
      get() {
        return this.sortDesc;
      },
      set(value) {
        this.sortDesc = value;
        if (value) this.params.order = "desc";
        else this.params.order = "asc";
      },
    },
    refListTable() {
      return this.listTable;
    },
    sortOptions() {
      return this.columns
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    selectCustomer: {
      get() {
        return this.selected.customer;
      },
      set(value) {
        this.selected.customer = value;
        if (value === null) {
          this.selected.customerBranch = null;
          this.filterCustomerBranch = [{ value: null, text: "Pilih Site" }];
        } else this.getCustomerBranchList(value);
      },
    },
  },
  async mounted() {
    this.filterCustomer = await this.getCustomer();
    this.selected.customer = this.filterCustomer[1].value;
    if (!this.permission.some((item) => item.name === "can_create_customer")) {
      this.getCustomerBranchList(this.selected.customer);
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Absensi" },
    ]);
  },
  methods: {
    sortChange(params) {
      Object.entries(params).forEach((item) => {
        if (item[1]) {
          // eslint-disable-next-line
          console.log(item[0], item[1]);
        }
      });
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    },
    async getTabel() {
      this.isLoading = true;
      try {
        await this.$axios
          .get(`${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/presence`, {
            params: {
              customer_id: this.selected.customer,
              customer_branch_id: this.selected.customerBranch,
              ...this.params,
            },
          })
          .then(({ data }) => {
            this.listTable = data.list;
            this.totalData = data.total;
          });
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message ?? "Terjadi Kesalahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async getCustomerBranchList(customer_id) {
      this.isLoading = true;
      if (
        !this.permission.some((item) => item.name === "can_create_customer")
      ) {
        const customerBranch = this.$store.getters.currentUser.customer_branch;
        this.filterCustomerBranch = [{ value: null, text: "Select All" }];
        customerBranch.map((item) => {
          this.filterCustomerBranch.push({
            value: item.id,
            text: item.name,
          });
        });
        this.selected.customerBranch = this.filterCustomerBranch[1].value;
      } else {
        await this.$axios
          .get(
            `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/customer/${customer_id}/customer-branches`
          )
          .then(({ data }) => {
            this.filterCustomerBranch = [{ value: null, text: "Select All" }];
            data.list.map((item) => {
              this.filterCustomerBranch.push({
                value: item.id,
                text: item.name,
              });
            });
          });
        this.selected.customerBranch = this.filterCustomerBranch[0].value;
      }
      this.isLoading = false;
    },
    processChange: debounce(function () {
      this.getTabel();
    }, 500),
    handleView(item) {
      const customerBranchId = item.customer_branch_id;
      this.$router.push({
        path: `/report/absensi/detail/${customerBranchId}/${item.date}`,
      });
    },
    // onCopy(e) {
    //   this.$bvToast.toast(e.text, {
    //     title: "Successfuly Copy to clipboard",
    //     variant: "success",
    //     solid: true,
    //   });
    // },
    sortChar,
    async exportAttendance() {
      const customerId = this.selected.customer;
      const customerBranchId = this.selected.customerBranch;
      const urlCondition = customerBranchId
        ? `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/presence/export/${customerBranchId}`
        : `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/presence/export/all/${customerId}`;
      await this.$axios
        .get(urlCondition, {
          params: {
            start_date: this.params.start_date,
            end_date: this.params.end_date,
          },
        })
        .then(({ data }) => {
          window.location.href = data.file;
        });
    },
    async exportAttendanceDetail() {
      const customerId = this.selected.customer;
      const customerBranchId = this.selected.customerBranch;
      const urlCondition = customerBranchId
        ? `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/presence/export/detail/${customerBranchId}`
        : `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/presence/export/detail/all/${customerId}`;
      await this.$axios
        .get(urlCondition, {
          params: {
            start_date: this.params.start_date,
            end_date: this.params.end_date,
          },
        })
        .then(({ data }) => {
          window.location.href = data.file;
        });
    },
  },
  watch: {
    params: {
      handler() {
        this.isLoading = true;
        this.processChange();
      },
      deep: true,
    },
    selected: {
      handler() {
        this.isLoading = true;
        this.processChange();
      },
      deep: true,
    },
  },
};
</script>
